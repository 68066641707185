import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import { ElMessage, ElDialog } from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import axios from 'axios'
import { get, post } from './network/request'
import router from './router'
import store from './store'
// 全局样式表
import './assets/css/base.css'
import 'element-plus/dist/index.css'

// 粒子效果
import Particles from '@tsparticles/vue3';
import { loadFull } from 'tsparticles';

const app = createApp(App)

// 添加全局变量
app.config.globalProperties.$message = ElMessage
app.config.globalProperties.$Dialog = ElDialog

app.config.globalProperties.$axios = axios
app.config.globalProperties.$get = get
app.config.globalProperties.$post = post
// 注册全部element图标
app.use(ElementPlus, { locale: zhCn })
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// 粒子效果
app.use(Particles, {
  init: async (engine) => {
    await loadFull(engine); // 加载完整的tsParticles库
    // 如果不需要Shapes或Animations，可以使用轻量级的库
    // await loadSlim(engine);
  }
});

app.use(store)
app.use(router)
app.mount('#app')
